@import url('https://fonts.googleapis.com/css2?family=Alexandria:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "AraHamah1964";
  src: local("AraHamah1964"),
    url(./Assets/Fonts/AraHamah1964.otf) format("opentype");
}
@font-face {
  font-family: "AraHamahBold";
  src: local("AraHamahBold"),
    url(./Assets/Fonts/AraHamahBold.ttf) format("truetype");
}
@font-face {
  font-family: "AraHamahSahet";
  src: local("AraHamahSahet"),
    url(./Assets/Fonts/AraHamahSahet.ttf) format("truetype");
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.collapsed-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4em; /* Adjust this according to your design */
  max-height: calc(1.4em * 2); /* 2 lines based on line height */
}




.new-header {
    padding: 15px 0px 15px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000; /* Ensures it stays above other elements */
    background-color:white
}

 .slider-inner-header{padding-top: 93px;}

.root_login main {margin-top: 0px;}
.root_logout main {margin-top: 130px;}

.root_login .renew main {margin-top: 0px;}
.root_logout .renew main {margin-top: 0px;}
main.contact-us,
main.about-page{margin-top: 130px;}

.new-banner-head{
  line-height: 70px;
}
.new-banner-pera{line-height: normal;}
.flex.new-banner-btn {display: inline-block;    margin-top: 28px;
}







.cursor-pointer svg{    
 display: inline-block;
  width: 18px;
  height: 18px;
  margin-top: -2px;
  margin-right: 5px; 
}

.new-header .new-sign svg {
  background: #f38328;
  width: 34px;
  height: 34px;
  margin: 3px;
  padding: 5px;
  border-radius: 100px;
}

.root_login .new-header .new-sign svg:hover{
  background: #ff7200;
}



.logout .new-sign a {display: block; margin-left: 20px;}
.login .new-sign a {display: contents;}



.new-header .new-sign svg path {
  fill: #fff;
  filter: brightness(0) invert(1);
}





.new-btn-light{
  background: #fff !important;
  color: #f38328 !important;
  /* padding: 8px 28px; */
  padding: 2px 18px 6px 18px;
  border: 1px solid #f38328;
  border-radius: 100px;
  font-family: AraHamah1964;
  font-size: 22px;
  font-weight: bold;
}




/* .new-btn-primary{
  background: #f38328;
  padding: 8px 28px;
  border: 1px solid #f38328;
  border-radius: 100px;
  color: #fff;
  font-size: 22px;
}
.new-btn-primary:hover {background: #ff7200;} */



.new-about-btn{
  line-height: normal;
}

.whyChoose-container .new-choose-banner{
 /* position: relative;
 z-index: -1; */
 padding: 40px 0 40px;
}
.new-choose-banner::after{
  background-color: transparent;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}



.new-banner-choose-pera{
font-weight: 700;
}



/* .new-choose-banner-icon{
  border-radius: 16px;
background: var(--BG-1, #FFF);
box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 4px 6px 0px rgba(34, 42, 53, 0.04), 0px 24px 68px 0px rgba(47, 48, 55, 0.05), 0px 2px 3px 0px rgba(0, 0, 0, 0.04);
width: 88px;
height: 88px;
margin: auto;
display: flex;
align-items: center;
justify-content: center;

}
.new-choose-banner-icon svg{
  width: 58px;
  height: 58px;
} */


/* .new-slide{max-width: 500px;margin: auto;} */
.new-show-btn {
  background: transparent;
  color: #000;
  font-family: 'AraHamahBold';
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  text-transform: capitalize;
}
.new-slide img{height: 260px; object-fit: cover; border-top-right-radius:8px; border-top-left-radius:8px}
.new-btn-head{padding-left: 1rem !important;}
.new-slide-head {padding-top: 0px;}
.new-slide-head .new-head-tag{font-size: 18px;     padding-top: 10px;   font-family: "Alexandria", serif;}
.new-corrate{
  font-family: "Alexandria", serif;
margin-bottom: 0px;
color: #3A3C43;
/* font-size: 20px; */
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
padding-bottom: 10px;
padding-top: 10px;

}
.new-slider-test{flex-direction: row-reverse;justify-content: left;}
.arabic .new-slider-test {flex-direction: row-reverse;justify-content: right;}

.new-slide-head .new-month{font-size: 14px;     bottom: -0.0em;}
.new-slide{display: flex; flex-wrap: wrap;justify-content: center;}


.renew .new-slide-slider {
  width: calc(84% - 10px) !important;
  min-width: 293px !important;
}
.new-slide-slider {
  width: calc(33% - 10px) !important;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(244, 244, 245, 1) 100%);
  padding: 10px;
  border-radius: 8px;
  border: 2px solid #f38328;
}
.slider-btn{
  margin-right: 0;
    padding: 4px 12px;
   
}




.new-slider-btn-info{display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center;}

/* .new-main-btn {margin-bottom: 15px;margin-left: 15px;margin-top: 0;font-size: 24px;max-width: 160px;} */
.new-text-head {    margin-bottom: 15px;
  align-items: normal;
  justify-content: space-between;
  padding-top: 10px;}
.new-packages-pera{line-height: 36px;}
.new-modal-head{font-size: 44px !important;     font-family: 'AraHamahBold';}
.new-modal-carrot{margin-bottom: -15px;}
.new-modal-month{font-size: 24px;margin-top: 8px;}
.new-modal-head{padding-top: .1rem;padding-left: 0rem;}
.new-modal-test{padding-left: 0;}
.slider-inner-box{padding-left: 15px !important; padding-right: 0px !important; }
/*========================== Form Design ==========================*/
.sing-pera{color: #525252;font-size: 58px;font-style: normal;font-weight: 700;line-height: 54px;}
.sing-fields input{border-radius: 10px;border: 1px solid #D9D9D9;padding: 10px 0px 10px 10px;}
.sign-label{color: #000;font-size: 28px;font-style: normal;font-weight: 700;line-height: normal;padding-bottom: 0px;
  font-family: 'AraHamahBold';}
.sign-img-bg{position: relative; height: 100vh;}
.sign-img-bg::after{content: "";position: absolute;width: 100%;height: 100%;background: #000000;top: 0;left: 0;opacity: .3;}
.sign-header{margin-top: 0px;}
.sign-form-info{padding: 0px 80px;}
.sign-label.mob-label {padding-top: 24px;margin-bottom: -20px;}
.form-split{display: flex; flex-wrap: wrap; justify-content: space-between;}
.form-split .form-split-banner{width: calc(50% - 10px);}
.sign-form-info .flex.justify-end a p:hover{color:#ff7200}

/*========================== Footer Design ==========================*/
.new-main-footer{padding-top: 15px; padding-bottom: 15px; align-items: center;}
.new-footer {width: 100%;}
.new-footer-banner {display: flex;flex-wrap: wrap;margin-top: 0 !important;}
.new-footer-banner .flex {width: calc(17% - -12px);margin-right: 10px; margin-bottom: 7px;margin-top: 7px;}
.arabic .new-main-footer {
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center;
  justify-content: space-between;
}

.arabic .arabic-footer-link{padding-right: 170px;}
/*========================== About page ==========================*/
.about-banner {padding: 15px 15px 15px; }
/* .slider-inner-box .about-banner{background: red;} */
.new-order-summary{padding-top: 130px;}
.new-notification{padding: 5px 15px 20px;}
.new-notification .new-btn-primary{margin-top: 15px !important;}
.new-load-more{line-height: normal;}
/* .new-admin{padding-top: 80px;} */
.login .new-sign .new-btn-light{padding: 0;margin: 0;border: none;}
.arabic .new-sign a {margin-left: 0px; margin-right: 20px; margin-right: 10px !important;  margin-left: 0px !important;}
/* .arabic .new-nav a span{font-size: 20px;} */

.logout .new-btn-light svg {
  margin: 0;
  background: transparent;
  padding: 0;
  width: 26px;
  height: 26px;
  margin-right: 4px;
}
.logout .new-btn-light svg path {
  fill: #f38328;
  filter: unset;
}


/* Arab Language */
.arabic .arb-services{    
  margin-left: 40px !important;
  margin-right: 0 !important;
}
.arabic .new-slide-slider{
  margin: 0 7px 0 !important;
 
}
.arabic .renew .new-slide-slider{
  width: calc(80% - 11px) !important;
}
.arabic  .new-slide-slider{
  width: calc(33% - 11px) !important;
}

/* .bg-homeBannerBg{
  padding: 30px 30px 30px 0px;
} */
/* .bg-homeBannerBg {
  height: 350px;
} */
/* .about-banner img{
  height: 200px;
}
.about-banner img {
  height: 400px;
  width: 100%;
  object-fit: contain;
} */

.footer-logo a:hover{color: #f38328;}
.form-eye {
  top: 46px;
}
.slider-inner-header-box{height: fit-content;}
.slider-inner-header-box .new-slider-btn-info{padding-bottom: 20px;}
.applicant-save{color: #f38328;}
.term-priv-pol .bg-homeBannerBg{    padding: 30px 30px 30px 30px;}
 
  .x-modal {
    background: #f38328;
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 29px;
    padding: 2px 0 8px;
    font-size: 36px;
    right: 10px;

}
.x-modal:hover{color: #fff;}

/* ========================================  Coming Soon  ======================================== */
/* .coming-section{   
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 8px;
  padding: 0px 30px 0px;
  text-align: center;
  margin-bottom: 60px;
  height: calc(100vh - 540px);
  position: relative;
}
.coming-section h1{
  font-size: 76px;
  font-family: 'AraHamahBold';
  color: #f38328;
  line-height: 1;
  margin-top: -25px;
}
.coming-section p{
  font-size: 24px;
  font-family: 'AraHamahBold';
  color: #525252;
}
.coming-center {
  position: absolute;
  left: 15px;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
} */
/* ========================================  Coming Soon End  ======================================== */
/* ========================================  View Blog All  ======================================== */


main .view-blog-banner {
  margin-top: 130px;
}
.view-blog-all{background:#FEF5EC; 
} 
.custom-container{ margin: auto;width: 100%;}
.view-blog-detail{text-align: center;}


/* .view-blog-head {
  padding-top: 20px;
} */
.blog-articles h2,
.view-blog-banner h1{
color: #525252;
font-family: 'AraHamahBold';
font-size: 60px;
font-style: normal;
font-weight: 700;
line-height: 54px;
}
.view-blog-banner h6{
color: #525252;
font-family: 'AraHamahBold';
font-size: 28px;
font-style: normal;
font-weight: 400;
line-height: 38px;
}

.view-blog-top{
  background: #F38328;
  color: #FFF;
  font-family: 'AraHamahBold';
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  padding: 4px 12px;
  border-radius: 16px;
  margin-bottom: 11px;
  display: inline-block;

} 

.view-blog-detail h1{padding-bottom: 11px;}
.view-blog-detail{padding-bottom: 42px;}




.view-blog-box{display: flex; flex-wrap: wrap; justify-content: center}
.view-blog-box .view-blog-info{    width: calc(33% - 28px);
  margin: 0 16px 32px; background: #fff; 
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  padding: 24px 24px 24px 24px;
position: relative;    transition: all 0.3s ease-in-out;
}

.full-clickable a {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 0;
}

.view-blog-box .view-blog-info:hover {
  /* translate: 0px -2px; */
  box-shadow: none;
}


.view-blog-info img {
  width: 100%;
  height: 245px;
  object-fit: cover;
}
.view-blog-info h5{
color: #F38328;
font-family: 'AraHamahBold';
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 20px;
padding-bottom: 6px;
}
.view-blog-info h2{
  color: #525252;
  font-family: 'AraHamahBold';
font-size: 38px;
font-style: normal;
font-weight: 700;
line-height: 38px;
padding-bottom: 12px;
}
 .view-blog-info p{

} 
.view-blog-product a{
color: #F38328;
font-family: 'AraHamahBold';
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: none;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
text-decoration: none;
margin-top: 22px;
display: inline-block;
position: relative;
}
.view-blog-product a::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #F38328;
  bottom: 5px;
  left: 0;
}


.view-blog-date{
  margin-top: 22px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.view-blog-product{
  margin-top: 26px;
}
.view-blog-product p{
  text-align: left;
}
.view-blog-date .view-blog-admin{
  color: #525252;
  font-family: 'AraHamahBold';
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 20px; 
display: block;
}

.view-blog-date .view-blog-admin a{
  color: #F38328;
  text-decoration: underline;
}

.view-blog-month{
  color:#525252;
  font-family: 'AraHamahBold';
  font-size: 22px;
}
.load-more-blog{text-align: center;     padding-top: 42px}
.load-more-blog a{
  display: inline-block;
  color: #FFF;
  font-family: 'AraHamahBold';
font-size: 19px; 
font-style: normal;
font-weight: 600;
line-height: 22px;
background: #F38328;
padding: 8px 14px 8px 32px; 
border-radius: 6px;
position: relative;
}
img.load-img {
  position: absolute;
  top: 10px;
  left: 11px;
}
/* ========================================  View Blog All End  ======================================== */
/* ========================================  Blog Single  ======================================== */
.blog-single-info{   margin-top: 40px;     padding-bottom: 45px;
  border-bottom: 1px solid rgb(0 0 0 / 30%);}
.blog-single-banner {
  width: "100%";
  
}
.blog-single-head {
  margin-top: 22px;
  /* width: calc(48% - 0px); */
  /* padding-left: 10px; */
  margin-bottom: "50px";
}
.blog-single-head h1 {
  margin-top: 12px;
  width:"100%";
}
.blog-single-head h1 {
  margin-top: -12px;
}
.blog-articles h2 {
  line-height: .6;
  display: inline-block;
  padding-left: 22px;
}
.blog-articles img {
  margin-bottom: -4px;
}
.blog-articles a {
  display: flex;
}

.blog-single-detail{
  display: flex; flex-wrap: wrap;     padding-top: 80px;
}
.blog-single-left {
  width: calc(70% - 0px);
  padding-right: 75px;
}
.blog-single-right {
  width: calc(30% - 2px);
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  padding: 20px 25px 20px ;
}

.blog-single-left p span{
  font-weight: bold;display: block;
  margin: 20px 0px;
}
.blog-single-detail .view-blog-info{margin-bottom: 50px; position: relative; padding-bottom: 20px;}
.blog-single-detail .view-blog-info::after{
  content: "";
  position: absolute;
  width: calc(100% - -50px);
  height: 2px;
  background: #000;
  left: -25px;
  bottom: 0;
}
.blog-single-detail .view-blog-info:last-child{margin-bottom: 0px;}
.blog-single-detail .view-blog-info{
  position: relative;
}
.blog-single-detail .view-blog-info .full-clickable a {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 0;
}




/* ========================================  Blog Single End  ======================================== */

/* ========================================  Home Blog  ======================================== */
.home-blog{background: #FEF5EC;}
.home-blog .view-blog-all{padding-top: 0;padding-bottom: 0;}
.home-blog .view-blog-detail {padding-bottom: 32px;}
.home-blog .load-more-blog {padding-top: 15px;}
.home-blog-head{padding-top: 50px;}
/* .home-blog-head h2{color: #525252;font-family: 'AraHamahBold';font-size: 68px;font-style: normal;font-weight: 700;line-height: 54px; } */
.home-blog-head h6{color: #525252;text-align: center;font-family: 'AraHamahBold';font-size: 24px;font-style: normal;
  font-weight: 400; line-height: normal;letter-spacing: 3.6px;text-transform: uppercase;}
.home-blog .load-more-blog a {padding: 4px 24px;
  border-radius: 100px;
  font-size: 24px;}
.home-blog .load-more-blog a:hover {background: #ff7200;}
/* ========================================  Home Blog End  ======================================== */
/* Other all pages changes */
.contact-us .bg-homeBannerBg,
.about-page .bg-homeBannerBg{height: auto; padding-bottom: 35px;}
.contact-us .bg-homeBannerBg p{line-height: 1.1;}
.about-page .bg-homeBannerBg p{line-height: 1.1;}
.contact-us .bg-homeBannerBg{padding-bottom: 25px;}
.main-pera-height{line-height: 1.1;}
.main-icon-bg {
  background: #f38328;
    border-radius: 100px;
    width: 40px;
    height: 40px;
    line-height: 50px;
    text-align: center;
    padding: 4px;
    top: -10px;
}
.main-icon-bg svg path {fill: #fff;filter: brightness(0) invert(1); line-height: 50px; text-align: center; display: inline-block;}
.choose-applicants .x-modal {
  top: 5px;
  right: 2px;
  width: 30px;
  height: 30px;
}

.choose-applicants .react-calendar{
  margin-top: -50px;
}
.choose-applicants .select-date{top:-5px}
.add-student{position: relative;padding-right: 50px;}
.add-student::after{
  content: "";
  position: absolute;
  background-image: url(./Assets/Images/log-in-page/add-student.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 15px;
  height: 15px;
  right: 20px;
  top: 17px;

}
.calender-icon svg {
margin-top: 6px;
}
.new-btn-disabled{
  border: none !important;
}
.account-edit{
  margin-top: 5px !important;
}
.account-edit h3{font-size: 26px;}
.term-cond-modal .bg-homeBannerBg {
  height: auto;    margin-bottom: 30px;
}
.term-cond-modal .x-modal {
  font-size: 20px;
}
.new-footer-logo svg {
  width: 130px;
 
}
.students-data .students-edit {
  background: #f38328;
  border-radius: 100px;
  width: 35px;
  height: 35px;
  padding: 4px;
}
.students-data .students-field {
  margin-top: 0 !important;
}
.students-data h1{
  font-size: 26px;
}

.add-student {
 
  display: inline-block !important;
  margin-top: 25px !important;
}
.account-btn-primary {
  font-size: 18px;
  padding: 4px 22px !important;
}
.main-head-height {
  line-height: .9;
  padding-bottom: 8px;
}

/* .mob-flex-direction{
  background: red;
  flex-direction: column-reverse;
} */


.blog-single-banner img{
width: 100%;
/* height: 200px; */
object-fit: cover;
}

.students-data-head{
  background: #fef3e9;
  padding-left: 24px;
  padding-top: 20px;
}
.new-order{
  color: #f38328;
}




.new-nav a span:hover{
  color: #F58428;
}

.new-close-button{
  font-size: 40px;
}






/* @media (min-width: 1600px) {
.blog-single-banner img{width: 100%;}
}
@media (max-width: 1600px) {
  .blog-single-banner img{width: auto;}
  }
   */

/*=========================== max-width: 1440px ===========================*/
@media (max-width: 1440px) {
/* .custom-container {padding: 0 15px;} */
}

/*=========================== max-width: 1400px ===========================*/
@media (max-width: 1400px) {
.new-footer-banner .flex {width: calc(17% - -62px)}
/* .custom-container {
  padding: 0 30px;
} */
.view-blog-box .view-blog-info {
  width: calc(33% - 29px);
}
}


/*=========================== max-width: 1396px ===========================*/

@media (max-width: 1396px) {
  .new-footer-banner .flex {width: calc(17% - -61px)}
}

/*=========================== max-width: 1381px ===========================*/

@media (max-width: 1381px) {
  .new-footer-banner .flex {width: calc(17% - -60px)}
}
/*=========================== max-width: 1399px ===========================*/
@media (max-width: 1399px){
  /* .custom-container {padding: 0 40px;} */
}
/*=========================== max-width: 1367px ===========================*/
@media (max-width: 1367px) {
  .new-footer-banner .flex {width: calc(17% - -59px)}
}
/*=========================== max-width: 1366px ===========================*/
@media (max-width: 1366px) {
/* .new-slider-btn-info div{width: 100%;} */
.new-main-btn {margin-left: 0;}
.new-footer-banner .flex {width: calc(17% - -59px)}
.slider-btn {margin-left: 0;}
/* .custom-container {padding: 0 46px;} */
.view-blog-box .view-blog-info {
  width: calc(33% - 29px);
}
.blog-single-head { padding-left: 50px;}
}
/*=========================== max-width: 1280px ===========================*/
@media (max-width: 1280px) {
  /* .custom-container {
    padding: 0 88px;
} */
/* .home-blog-head h2 {font-size: 58px;line-height: 54px;} */
  }


/*=========================== max-width: 1200px ===========================*/
@media (max-width: 1280px) {
  .new-footer-banner .flex {width: calc(24% - -47px)}

  }



/*=========================== max-width: 1188px ===========================*/
@media (max-width: 1188px){
.new-slide-slider {width: calc(33% - 17px) !important;margin: 0 10px 0px;}
.new-slide {flex-direction: row;}
.blog-single-head { padding-left: 40px;}




}
/*=========================== max-width: 1200px ===========================*/
@media (max-width: 1200px){
.sign-form-info {padding: 0px 20px;}
.sing-pera {font-size: 50px;line-height: 44px;padding-top: 15px;}
.blog-articles h2,
.view-blog-banner h1 {

  font-size: 58px;

  line-height: 44px;
}
.blog-single-left {

  padding-right: 55px;
}
.blog-single-head {
  width: calc(52% - 0px);
}
.blog-single-banner {
  width: "100%";
}
.blog-single-head h1 {
  margin-top: -10px;
}
.view-blog-date {
  margin-top: 10px;
}
.blog-single-detail {padding-top: 70px;}
}



/*=========================== max-width: 1073px ===========================*/
@media (max-width: 1073px){
  .view-blog-box .view-blog-info {
    width: calc(33% - 18px);
    margin: 0 10px 20px;
    padding: 15px 15px 20px 15px;
}
.view-blog-info h2 {
  font-size: 34px;
  line-height: 26px;
}
/* .custom-container {
  padding: 0 80px;
} */
/* .home-blog-head h2 {font-size: 48px;line-height: 44px;} */
}


/*=========================== max-width: 1067px ===========================*/
@media (max-width: 1067px){
  .new-slide-slider {width: calc(32% - 9px) !important;margin: 0 10px 0px;}
  .arabic .renew .new-slide-slider {width: calc(84% - 12px) !important;}
  .slider-btn {
  
    width: 120px !important;
}
  }


/*=========================== max-width: 1025px ===========================*/
@media (max-width: 1025px){

  /* .about-banner img {
    height: 357px;
  
} */
.blog-single-left {
  padding-right: 35px;
}
}


/*=========================== max-width: 1024px ===========================*/
@media (max-width: 1024px){
.new-slide-slider { margin: 0 10px 0px;}
.arabic .new-slide-slider {width:100% !important;}
.new-banner-head {line-height: 50px;}
.slider-inner-header .slider-inner-header-box{padding-left: 15px; padding-right: 15px;}
.form-split .form-split-banner{width:100%;}
.term-cond{display: block !important;}
/* .about-banner img {height: 100%;} */
.blog-single-head { padding-left: 30px;}
}


/*=========================== max-width: 1023px ===========================*/
@media (max-width: 1023px){

  /* .about-banner img {
    width:  100%;
    height: 100%;
  
} */
.new-slide-slider {
 
  width: 100% !important;
}
.coming-section h1{
  font-size: 76px;

}
.coming-section p{
  font-size: 22px;

}
.mob-flex{
  margin-right: auto;
  margin-bottom: 20px;
}

}


/*=========================== max-width: 992px ===========================*/
@media (max-width: 992px){
  /* .custom-container {
    padding: 0 40px;
} */
.blog-articles h2,
.view-blog-banner h1 {
  font-size: 48px;
  line-height: 34px;
}
.view-blog-banner h6 {
  font-size: 24px;
  line-height: 28px;
}
/* .home-blog-head h2 {font-size: 40px;} */
.blog-single-head h1 {
  margin-top: -6px;
}
.blog-single-detail {padding-top: 60px;}
.new-footer-logo svg {
  width: auto;
}
  }


/*=========================== max-width: 850px ===========================*/
@media (max-width: 850px){
.logout .new-sign a {margin-left: 10px;}
.new-header .new-btn-light,
.new-header .new-btn-primary {padding: 8px 18px;}
.logout .new-btn-light svg {margin: 0;padding: 0;width: 24px;height: 24px;margin-right: 4px;}
.new-header a span{margin: 0 8px 0;}
}



/*=========================== max-width: 800px ===========================*/
@media (max-width: 800px){
  .view-blog-box .view-blog-info {
    width: calc(50% - 20px);
}
.blog-single-detail {padding-top: 50px;}

main .view-blog-banner {margin-top: 230px;}
  }
  





/*=========================== max-width: 768px ===========================*/
@media (max-width: 768px){
.sing-pera {font-size: 44px;line-height: 38px;}
.new-slide-slider {width: calc(100% - 9px) !important;margin: 0 10px 0px;}
.new-footer-banner .flex {width: calc(24% - -44px);margin-left: 10px;}
.new-footer-follow{margin-left: 10px !important ;margin-right: 10px !important ;}
.new-banner-head {line-height: 40px;}
/* .new-home-banner {padding: 30px 40px 30px;} */
.new-btn-light {padding: 8px 10px;}
.logout .new-sign a {margin-left: 14px;}
.new-sign,
.new-nav{margin-bottom: 0;}
.coming-section h1 {
  font-size: 56px;
}
.coming-section p {

  line-height: 1.2;
}
.view-blog-box .view-blog-info {
  width: calc(50% - 20px);
}
.blog-articles h2,
.view-blog-banner h1 {
  font-size: 40px;

}
.blog-single-right {
  width: calc(35% - 2px);
  padding: 20px 20px 20px;
}
.blog-single-left {
  width: calc(65% - 0px);
 
}
.blog-single-detail .view-blog-info {
  margin-bottom: 30px;
}
}
/*=========================== max-width: 767px ===========================*/
@media (max-width: 767px){
.new-footer-banner .flex {width: calc(50% - 50px);}
/* Root  Logout */
.root_logout main{margin-top: 105px;}
.root_login main {margin-top: 60px;}
.view-blog-info img{
  width: 100%;
}
.view-blog-box .view-blog-info {
  width: 100%;
  margin: 0 0px 20px;
}

.blog-single-right {
  width:100%;
  padding: 20px 0px 20px;
}
.blog-single-left {
  width:100%;
  padding-right: 0px;
 
}
.blog-single-head {
  width: 100%;padding-left: 0px; margin-bottom: 30px;
}
.blog-single-banner {
  width:100%;
 
}
.blog-single-info {

  flex-direction: column-reverse;    margin-top: 15px;    padding-bottom: 20px;

}
.blog-articles h2 {
  font-size: 30px;
  padding-left: 10px;
}
.blog-articles a:hover h2{
  color: #f38328;
}
.blog-articles img {
  margin-bottom: 0px;
  max-width: 14px;
}
.blog-single-detail {padding-top: 40px;}

.blog-single-detail .view-blog-info::after {
  width: 100%;
  left: 0px;

}
}
/*=========================== max-width: 640px ===========================*/
@media (max-width: 640px){
.new-admin {padding-top: 120px;}
.slider-inner-header {padding-top: 80px;}
main .view-blog-banner {
  margin-top: 260px;
}
}
/*=========================== max-width: 575px ===========================*/
@media (max-width: 575px){
.new-footer-banner .flex {width:100%;}
.new-footer-logo{justify-content: start;}
.new-footer-logo-terms{justify-content: flex-end;}

.new-footer-logo svg{width: 100%;}
.new-footer {margin: 15px 0 0 0;}
}
/*=========================== max-width: 336px ===========================*/
@media (max-width: 336px){
.new-header a span {margin: 0 5px 0;}
}


.on_of_month{
  border: 1px solid #f38328 !important
}



/* ======================== Banner Section ======================== */

.theme-bg-primary{ background: #F58428}
.theme-bg-secondary{ background: #4BAF4F}
.theme-bg-light{ background: #B3DA3F}
.theme-bg-dark{ background: #F0F4F7}



.theme-text-light{color: #4BAF4F;}


.head-span{color: #F58428;}




.text-primary{color: #fff;}
.text-secondary{color: #3A3C43;}
.banner-container .new-banner-head{
  font-family: "Alexandria", serif;
  font-size: 60px;
  font-weight: 700;
  line-height: 80px;
  }
.new-banner-head-htwo{
font-family: "Alexandria", serif;
font-size: 40px;
font-weight: 700;
line-height: 60px;
color: #3A3C43;
}

.new-banner-head-tree{
  font-family: "Alexandria", serif;
font-size: 28px;
font-weight: 700;
line-height: 40px;


}

.new-banner-head-four{
  font-family: "Alexandria", serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 35px;
}



.new-banner-pera{
  font-family: "Alexandria", serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 42px;
  padding-top: 26px;
  }

.new-banner-large-pera{
font-family: "Alexandria", serif;
font-size: 24px;
font-weight: 400;
line-height: 34px;
color: #3A3C43;
  }

.new-banner-common-pera{
font-family: "Alexandria", serif;
font-size: 20px;
font-weight: 400;
line-height: 25px;
color: #3A3C43;

  }



.root_logout .banner-container{
  margin-top: -38px;
}


.banner-container{
  background-image: url(./Assets/Images/newBanner.png);
  height: 650px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  margin-top: 71px;
}
.banner-container::after{
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  background-image: url(./Assets/Images/newBannerFooter.png); 
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50px;
  width: 100%;
}
.banner-container .banner-content{
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}




.banner-container .new-banner-main {
  max-width: 826px;
  margin-top: -60px;
}





.new-btn-primary{
  background: #F58428;
  font-family: "Alexandria", serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  color: #fff;
  padding: 8px 16px 8px 16px !important;
  border-radius: 10px;
  position: relative;
  margin: 0 !important;
  width: auto !important;
  transition: all 0.2s ease-in-out;
  border: none;
}
.view-blog-box .view-blog-info .slider-btn{ padding: 8px 54px 8px 16px !important;}
.view-blog-box .view-blog-info .slider-btn:hover::after{
  translate: 6px 0px;
}
.new-btn-primary:hover{
  background: #ff7200;
}


.support-container .new-btn-primary::after,
.quality-container .new-btn-primary::after,
.meals-container .new-btn-primary::after,
.mealData-container .new-btn-primary::after,
.about-container .new-btn-primary::after,
.health-container .new-btn-primary::after,
.banner-container .new-btn-primary::after{
  content: "";
  position: absolute;
  width: 40px;
  height: 13px;
  right: 12px;
  top: 15px;
  background: url(./Assets/Images/arrow_btn.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  transition: all 0.2s ease-in-out;
} 

.support-container .new-btn-primary,
.quality-container .new-btn-primary,
.meals-container .new-btn-primary,
.mealData-container .new-btn-primary,
.about-container .new-btn-primary,
.banner-container .new-btn-primary{
  padding: 8px 54px 8px 16px !important;
}



.support-container .new-btn-primary:hover,
.quality-container .new-btn-primary:hover,
.meals-container .new-btn-primary:hover,  
.mealData-container .new-btn-primary:hover,  
.about-container .new-btn-primary:hover,
.banner-container .new-btn-primary:hover{
  background: #ff7200;
}


.support-container .new-btn-primary:hover::after,
.quality-container .new-btn-primary:hover::after,
.mealData-container .new-btn-primary:hover::after,
.meals-container .new-btn-primary:hover::after,
.about-container .new-btn-primary:hover::after,
.banner-container .new-btn-primary:hover::after{
  translate: 6px 0px;
}





/* =================== About Section =================== */
.about-container{
  background-image: url(./Assets/Images/about-bg.png);
  height: 420px;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;

}

.about-container span.about-top {
  display: block;
}


.about-container img.about-img {

  max-width: 340px;
  height: auto;
  margin-right: 60px;
}

.about-container .about-info {
  max-width: 870px;
}

.about-container .about-banner{align-items: center;}
/* =================== Why Choos Section =================== */
.new-choose-banner .new-choose-box {

  width: calc(25% - 46px);
  text-align: center;
  margin: 0 20px 0px;
}


.new-choose-banner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.new-choose-banner .new-choose-banner-icon{
  text-align: center;
  display: inline-block;
  border-radius: 100px;
  width: 115px;
  height: 115px;
  line-height: 106px;
  margin-bottom: 20px;
}
.new-choose-banner svg{text-align: center; display: inline-block}
.new-choose-banner p{margin-top: 10px; color: #3A3C43;}


/* ===================  latest meals Section =================== */
.meals-container .new-slide-slider{border: none;border-radius: 0;}

.meals-container .new-slide-slider{  transition: all 0.3s ease-in-out;}
.meals-container .new-slide-slider:hover{
  transform: scale(1.1);
}
 .meals-container .new-btn-primary {font-size: 12px;padding: 5px 34px 5px 11px !important;}
  .meals-container .new-btn-primary::after {
   
    width: 30px;
    height: 10px;
    right: 7px;
    top: 13px;

}



.meals-container{
  background-image: url(./Assets/Images/latest-meals-bg.png);
  height: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 50px;

  position: relative;
}

.meals-container::before,
.meals-container::after{
  content: "";
  position: absolute;
  left: 0;
  background-image: url(./Assets/Images/newBannerFooter.png); 
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50px;
  width: 100%;
}

.meals-container::before{
  bottom: 0;-webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.meals-container::after{
  top: 0;    transform: rotate(180deg);
}



.meals-container .new-slide-slider {
  width: 100% !important;
  margin: 0 24px 0 !important;
  background: transparent;
}

.meals-container .new-slide-head {
  margin-top: 20px;
}

 .meals-container .new-package-banner {
  padding: 60px 0px 30px;

}
 .meals-head{
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
 }




.whychoose-container .whychoose-box {
  width: calc(31% - 0px);
}

.whychoose-container .whychoose-img{ width: calc(30% - 0px); }


.whychoose-container .whychoose-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
}

.whychoose-container .whychoose-img img{display: inline-block}

.whychoose-container .whychoose-left-inner {
  padding: 40px 20px 35px;
  position: relative;
}

.whychoose-container .whychoose-left-inner::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(./Assets/Images/store-bg.png); 
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;;
  opacity: .1;
    left: 0;
}
.whychoose-container .whychoose-left-inner h4{position: relative;}

.whyChoose-container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.whychoose-container .whychoose-left .whychoose-left-inner{border-radius: 30px 0px 0px 30px; margin-bottom: 50px;}
.whychoose-container .whychoose-right .whychoose-left-inner{border-radius: 0px 30px 30px 0px; margin-bottom: 50px;}





.meal-container .meals-banner {
  display: flex; flex-wrap: wrap;    margin-top: 30px;
}
.meal-container .meal-box {
  width: calc(24% - 28px);
  margin: 0 20px 40px;
  padding: 40px 20px 40px;


}

.meal-container .meal-box:hover .meal-button{
  opacity: 1;

}



.meal-container .meal-box h4{padding: 0px 10px; text-align: center;}

.meal-container .meal-info {
  min-height: 290px;
}

.meal-container .meal-box{position: relative;}
.meal-container .meal-button{
  position: absolute;
  background-color: #000000a3;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
justify-content: center;
align-items: center;
opacity: 0;
transition: all 0.3s ease-in-out;
}


.meal-info img {
  height: 260px;
  width: 100%;
  object-fit: contain;
}

/* ===================  Quality Section =================== */
.quality-container .about-banner{display: flex; align-items: center;}



.quality-container .about-banner{
  display: flex;
  flex-wrap: wrap;
}

.quality-container .about-info {
  width: calc(60% - 0px);
}

.quality-container .about-img {
  width: calc(40% - 0px);
  text-align: center;
  display: inline-block;
}

.quality-container .about-img img {
  width: 360px;
  text-align: center;
}






/* ===================  Image Slider Section =================== */
.imagesec-container {overflow: hidden;}
.imagesec-container .about-banner{padding: 0; position: relative;}
.imagesec-container .about-banner::before,
.imagesec-container .about-banner::after{
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50px;
  width: 100%;}
.imagesec-container .about-banner::before{
  bottom: 0;background-image: url(./Assets/Images/newBannerFooter.png); z-index: 1;}
.imagesec-container .about-banner::after{
  top: 0;  background-image: url(./Assets/Images/wave-top.png);} 
  .imagesec-container .slider-info img{height: 650px; object-fit: cover; width: 100%;}


  /* ===================  Support Section =================== */


  .support-container .support-section {
  display: flex;
      flex-wrap: wrap;
      align-items: end;
      justify-content: end;
      align-items: center;
      padding: 30px 20px 30px 0px;
      position: relative;
      border-radius: 100px 0px 0px 100px;
  
   
} 







.support-container.arabic-banner .support-section {
  display: flex;
      flex-wrap: wrap;
      align-items: end;
      justify-content: normal;
      align-items: center;
      padding: 30px 20px 30px 20px;
      position: relative;
      border-radius: 0px 100px 100px 0px;
      justify-content: left;
      flex-direction: row-reverse;
      background: #4BAF4F;
  
   
} 
/* 
.arabic .support-container.arabic-banner .support-section{
  flex-direction: row;
} */







.support-container.arabic-banner .flex.new-banner-btn {
  text-align: end;
}


.support-container.arabic-banner .support-img {

  right: -20px;
  left: auto;

}

.support-container.arabic-banner .support-info {
  width: calc(60% - 0px);
  padding-left: 50px;
}


.arabic .support-container .support-section{
  border-radius: 0px 100px 100px 0px;
}



.support-container .new-banner-head-htwo {
  font-size: 32px;
  line-height: 52px;
}


.support-container .new-banner-btn {
    width: calc(15% - 0px);
}
.support-container .flex.new-banner-btn {
 margin-top: 0px;
}

.support-container .support-info {
    width: calc(61% - 0px);    padding-right: 50px;
}

.support-container.arabic-banner .support-info {
  width: calc(57% - 0px);    padding-right: 0;padding-left: 130px;
}



.support-container .support-img{
  position: absolute;
  left: -20px;
  max-width: 320px;
  top: 50%;
  transform: translateY(-50%);
}

.arabic .support-container .support-img{
  position: absolute;
  right: -20px;
  max-width: 320px;
  top: 50%;
  transform: translateY(-50%);
}



  /* ===================  Gallery Section =================== */



.gallery-container .gallery-slider{max-width: 1920px; margin: 0 auto;}
.gallery-container .about-banner{padding: 0;}
.gallery-container .gallery-slider {
  display: flex;
  flex-wrap: wrap;
}

.gallery-container .gallery-center .gallery-full{ overflow: hidden;}
.gallery-container .gallery-center .gallery-full img{transition: all 0.3s ease-in-out;height:605px; object-fit:cover;}


.gallery-container .gallery-center .gallery-full:hover img{transform: scale(1.1);}


.gallery-container .gallery-left .gallery-full img{height: 306px;}


.gallery-left {
  width: calc(30% - 0px);
}

.gallery-center {
  width: calc(40% - 0px);
}


.gallery-container .gallery-banner {
  display: flex;

}
.gallery-container .gallery-full img{width: 100%;}



.gallery-container .gallery-left .gallery-half img{transition: all 0.3s ease-in-out;width: 300px; height: 300px; object-fit: cover;}







  /* ===================  Partner Section =================== */
.home-blog h2{  
  display: inline-block;
  padding: 0 85px;
  position: relative;

}

.home-blog h2::before,
.home-blog h2::after{
  content: "";
  position: absolute;
  background-image: url(./Assets/Images/partner-icon.svg); 
  width: 70px;
  height: 70px;
  top: -3px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.home-blog h2::after{
  left: 0;
}
.home-blog h2::before{
  right: 0;
}

.home-blog .view-blog-box{margin-top: 20px;}

.home-blog .view-blog-box .overflow-hidden {
  display: flex
;
  justify-content: center;
}
.home-blog .view-blog-box .overflow-hidden img {
  width: 80%;
  height: 270px;
}




footer .new-footer{background: #A3CDA0;}


footer .new-footer .footer-text-icon-flex {
      display: flex;
      flex-wrap: wrap;
  
     justify-content: space-between;

}

.arabic .main-demo .demo {

  justify-content: center;
}  



footer .new-footer .footer-icon-link {
  margin: 0px 10px 15px;
  /* background: red; */
}
.demo {

  display: flex;
      flex-wrap: wrap;
      align-items: end;
      justify-content: center;
      padding-left: 60px;
}
.arabic .main-demo{margin-left: 100px;}
.main-demo {
  margin-right: 70px;
}

.new-footer-logo-terms{padding-top: 33px;}
footer .footer-text-icon-flex a {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  color: #fff;
}
footer .new-footer .footer-icon-link a svg{margin-right: 10px;}

footer .footer-icon{display: flex; justify-content: center; flex-wrap: wrap;}
footer .footer-icon .footer-icon-blank{    margin: 15px 0px 0px 15px;}

footer .footer-text-icon {
  margin-top: 26px;
}

footer .footer-info{

  margin: 10px 20px 0px 20px;
  text-align: center;
}
footer .footer-info p{
  color: #fff;
  padding-bottom: 0px;
  
}


 .new-header .new-btn-primary::after{display: none;} 
.new-header .new-btn-primary {
  padding: 8px 18px 6px 18px !important;
  border-radius: 60px;
  margin-left: 10px !important;
  height: 42px;
}





.new-design-banner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.new-design-banner .new-design-width {
  width: calc(60% - 0px);
}

.new-design-banner .new-design-width-left {
  width: calc(70% - 15px);
}

.new-design-width-right {
  width: calc(30% - 15px);
}
.new-admin {

  padding-top: 130px;
}
.new-order-summary .new-btn-primary{border: none;}
.home-blog-container .view-blog-detail h2::before,
.home-blog-container .view-blog-detail h2::after{
  display: none;
}
.home-blog-container .view-blog-detail h2{padding: 0;}
.home-blog-container .view-blog-box .view-blog-info{  width: calc(25% - 28px);
}
.home-blog-container .full-clickable a{pointer-events: none;}
.home-blog-container h3{font-size: 20px;    line-height: 30px;
}

.home-blog-container .new-slide{margin-top: 0px;}
.home-blog-container .view-blog-date{margin-top: 5px;}

/* ===================  Inner Pages Section =================== */
/* ===================  Inner Pages Section =================== */
/* ===================  Inner Pages Section =================== */
/* ===================  Inner Pages Section =================== */
/* ===================  Inner Pages Section =================== */
.contact-new-banner,
.about-new-banner{
     background-image: url(./Assets/Images/oldHomeBannerBackground.png);
     width: 100%;
     height:auto;
     background-repeat: no-repeat;
     background-position: center center;
     background-size: cover;
     border-radius: 30px;
}



.add-student {

  padding: 8px 40px 8px 16px !important;
  margin-top: 20px !important;
}


.add-student::after {

 
  width: 15px;
  height: 15px;
  right: 14px;
  top: 13px;
}


.arabic .english-banner .support-section{
  border-radius: 100px 0px 0px 100px;
}
.notification-page {margin-top: 130px;}
.notification-page .new-btn-primary{margin-top: 30px !important; border: none;}





.new-nav a span{
  margin-left: 1rem;
  margin-right: 1rem;
}

.new-nav {
  transition: all 0.3s ease;
}

.new-sign {
  padding-left: 230px;
}

.arabic .new-sign {
  padding-left: 0px;
  padding-right: 230px;
}






@media (max-width: 1023px) {
  .new-nav {
    display: none;
  }
  .new-nav.block {
    display: block;
  }
  .new-home-banner .new-sign {
      width: auto !important;
      margin-top: 10px;
      /* margin-left: -134px;   */
      justify-content: start;
      padding-left: 0px !important;
      
  }

}  
















/* ===================  Media Query Section =================== */
/* ===================  Media Query Section =================== */
/* ===================  Media Query Section =================== */
/* ===================  Media Query Section =================== */
/* ===================  Media Query Section =================== */




@media (min-width: 1024px){
.about-banner-flex{         width: 56% !important;}
}
@media (min-width: 1200px){
.about-banner-flex {
  width: 62% !important;
}
}

@media (min-width: 1280px){
  .about-banner-flex {
    width: 64% !important;
  }
  }

  @media (min-width: 1366px){
    .arabic .about-banner-flex,
    .about-banner-flex {
      width: 70% !important;
    }

    }

/* ===================  Media Query 1601px  =================== */
@media (max-width: 1601px){
  .support-container.arabic-banner .support-info {
    width: calc(53% - 0px);
}
}

/* ===================  Media Query 1600px  =================== */
@media (max-width: 1600px) {
  .banner-container .new-banner-head {
    font-size: 50px;
    line-height: 70px;
  }
  .new-banner-pera {
    font-size: 28px;
    padding-top: 16px;
    max-width: 640px;
}
  }
/* ===================  Media Query 1520px  =================== */
@media (max-width: 1520px){
  .meals-container .new-slide-slider {
    /* width: calc(20% - -9px) !important; */
  }
}
  /* ===================  Media Query 1480px  =================== */
  @media (max-width: 1480px){
    .main-demo {
      margin: 0 0 0 0px;
    }
    .support-container.arabic-banner .support-info {
      width: calc(58% - 0px);
  }
  }
  
  /* ===================  Media Query 1440px  =================== */
  @media (max-width: 1440px){
    .banner-container {
      height: 680px;
  }
  .new-banner-head-htwo {
    font-size: 36px;
    line-height: 55px;
}
.new-banner-large-pera {
  font-size: 22px;
  line-height: 30px;
}
.new-banner-head-tree {

  font-size: 24px;

}
.support-container .support-info {
  width: calc(57% - 0px);   
}


.new-corrate {
  font-size: 16px;

}
.new-slide-head .new-head-tag {
  width: 90px;
}
.new-slide-head .new-head-tag {
  font-size: 18px;

}
.new-slide-head .new-head-tag {
  padding-top: 0px;

}

.new-sign {
  padding-left: 180px;
}
.arabic .new-sign {
  padding-right: 180px;
}
.home-blog-container h3 {
  font-size: 17px;

}
}





/* ===================  Media Query 1399px  =================== */
@media (max-width: 1399px) {
  .banner-container{

    height: 600px;
 
  }
  .meals-container .new-slide-slider {
    /* width: calc(20% - -28px) !important; */
    margin: 0 18px 0 !important;
    background: transparent;
}
.new-slide-head .new-head-tag {
  font-size: 16px;
  padding-top: 0px;
  font-family: "Alexandria", serif;
}
.new-slide-head .new-month {
  font-size: 12px;
  bottom: -0.0em;
}
.meals-container .new-btn-primary::after {
  width: 28px;
  height: 9px;
  right: 1px;
  top: 13px;
}



  }



  /* ===================  Media Query 1390px  =================== */


  @media (max-width: 1390px){

    .demo-b {
 
      max-width: 770px;
      margin: 0 auto;
  }
    }
    


  /* ===================  Media Query 1366px  =================== */


  @media (max-width: 1366px){
    .whychoose-container .whychoose-left-inner {
      padding: 30px 20px 25px;
     
  }
  .whychoose-container .whychoose-left .whychoose-left-inner {
    margin-bottom: 40px;
    min-height: 160px;
}

.whychoose-container .whychoose-right .whychoose-left-inner {
 
  min-height: 160px;
}

.meal-container .meal-box {
  width: calc(19% - -33px);
}


.support-container .support-img {
  
  max-width: 290px;

}
.support-container .new-banner-head-htwo {
  font-size: 28px;
  line-height: 44px;
}

.gallery-center,
.gallery-left {
  width: calc(33% - 0px);
}

.home-blog h2 {

  padding: 0 70px;

}
.home-blog h2::before, 
.home-blog h2::after{
  width: 50px;
  height: 50px;
}
.new-sign {
  padding-left: 100px;
}
.arabic .new-sign {
  padding-right: 100px;
}

  }


  /* ===================  Media Query 1300px  =================== */
  @media (max-width: 1300px){
    .meal-container .meals-banner {
      justify-content: center;
  }



  .meals-container .new-slide-slider{
    margin: 0 0px 0 !important;
    /* width: calc(23% - -19px) !important; */
  }



  }


  /* ===================  Media Query 1280px  =================== */
  @media (max-width: 1280px){
 
  .arabic .new-nav a span {
    margin: 0px 5px !important;
  }
  }





  /* ===================  Media Query 1200px  =================== */
  @media (max-width: 1200px){
    .new-banner-head-tree {

      font-size: 22px;
    
    }
    .new-choose-banner p {
      margin-top: 0px;
  }
  .banner-container .new-banner-head {
    font-size: 40px;
    line-height: 55px;
    max-width: 500px;
}

.new-banner-pera {
  font-size: 24px;
  padding-top: 16px;
  max-width: 580px;
  line-height: 38px;
}


.quality-container .about-info {

  padding-right: 70px;
}
.support-container .new-banner-btn {
  width: calc(16% - 0px);
}
.support-container .support-info {
  width: calc(53% - 0px);
  padding-right: 30px;
}



.demo-b {
 
  max-width: 610px;
  margin: 0 auto;
}
.new-footer-logo {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .new-footer-logo {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: space-evenly;
      align-items: center;
  }

  .arabic .demo {
    padding-left: 0px;
}
  .demo {
    padding-left: 0px;
}
.arabic .arabic-footer-link {
  padding-right:0px; 
}
}


.demo {

width: 100%;
justify-content: center;

}



.support-container.arabic-banner .flex.new-banner-btn {
  text-align: end;
  width: 160px;
}
.support-container.arabic-banner .support-info {
  width: calc(55% - 0px);
  padding-left: 100px;
}
.slider-inner-header {
  padding-top: 109px;
}
.new-nav a span{margin: 0px 5px !important;}


.home-blog-container .view-blog-box .view-blog-info {
  width: calc(45% - 30px);
}


  }





  /* ===================  Media Query 1180px  =================== */
  @media (max-width: 1180px){
    .arabic .new-sign {
      padding-right: 100px;
  }
  }




  
  /* ===================  Media Query 1100px  =================== */
  @media (max-width: 1100px){
  .support-container .support-info {
    width: calc(47% - 0px);

}
.support-container .new-banner-btn {
  width: calc(19% - 0px);
}
.arabic .new-nav a span{font-size: 20px;}


.new-sign {
  padding-left: 80px;
}
}





  /* ===================  Media Query 1024px  =================== */
@media (max-width: 1024px){
.about-container {height: 100%;}
.about-container .about-info {margin-top: 30px;}
.meal-container .meal-box {width: calc(40% - -25px);}
.quality-container .about-img {width: 100%;margin-top: 30px;text-align: left;}
.quality-container .about-info {width: 100%;padding-right: 0px;}
.arabic .support-container .support-section,
.support-container .support-section {
  padding: 30px 30px 30px 30px;
  border-radius: 0px 0px 0px 0px;
  justify-content: flex-start;
}

.arabic .main-demo {
  margin-left: 0px;
}

.support-container.arabic-banner .support-section{
  padding: 30px 30px 30px 30px;
  border-radius: 0px 0px 0px 0px;
  justify-content: end;
}
.support-container.arabic-banner .support-section .support-info{text-align: end}
.support-container .support-info{padding-right: 0;}
.support-container.arabic-banner .support-info {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
}
.support-container.arabic-banner .support-img {
  right: -20px;
  left: auto;
  width: 100%;
}

.arabic .support-container .support-img,
.support-container .support-img {
  position: initial;
  transform: initial;
  margin-bottom: 30px;
}

.support-container .new-banner-btn,
.support-container .support-info {
  width: 100%;
}

.arabic .support-container .new-banner-btn,
.arabic .support-container .support-info {
  width: 100%;
}

.arabic .support-container .flex.new-banner-btn,
.support-container .flex.new-banner-btn {
  margin-top: 20px;
}

.gallery-center,
.gallery-left {
  width: 100%;
}
.gallery-container .gallery-left .gallery-half img {
  width: 100%;
  height: 300px;
}
.gallery-container .gallery-two .gallery-full, 
.gallery-container .gallery-one .gallery-half, 
.gallery-container .gallery-one .gallery-full {
  width: 100%;
}
.gallery-container .gallery-left .gallery-half, 
.gallery-container .gallery-center .gallery-full {
  width: 100%;
}
.gallery-container .gallery-center .gallery-full img {
  height: auto;
}
.new-header .new-btn-light {padding: 8px 16px;}

.new-design-width-right {
  width: calc(40% - 15px);
}
.new-design-banner .new-design-width-left {
  width: calc(60% - 15px);
}

/* ================================= arabic ================================= */
/* ================================= arabic ================================= */
/* ================================= arabic ================================= */
/* ================================= arabic ================================= */
.arabic .about-container .about-banner {
  width: 100%;
  justify-content: flex-end;
}
.arabic .about-container img.about-img {
  margin-right: 0px;
}
.arabic .quality-container .about-img {
  margin-top: 20px;
  text-align: right;
}
.arabic .new-sign {
  padding-right: 0px;
}

}

  /* ===================  Media Query 1023px  =================== */
  @media (max-width: 1023px){
    .demo-b {
      max-width: 100%;
      margin: 0 auto;
  }
  footer .new-footer .footer-text-icon-flex {
 
    margin-top: 20px;
}

.meals-container .new-slide-slider {
  /* width: calc(31% - -20px) !important; */
}

  }




  /* ===================  Media Query 992px  =================== */
  @media (max-width: 992px){
  .new-choose-banner .new-choose-box {
    width: calc(50% - 40px);
    margin: 0px 20px 40px;
}


.whychoose-container .whychoose-box {
  width: 100%;
}
.whychoose-container .whychoose-img {
  width: 100%;
  margin-bottom: 40px;
}
.whychoose-container .whychoose-left .whychoose-left-inner ,
.whychoose-container .whychoose-right .whychoose-left-inner{
  min-height: auto;
}

.meals-container .new-slide-slider {
  width: 100% !important;

}
.new-nav {
  width: 400px;

}
}







  /* ===================  Media Query 860px  =================== */
  @media (max-width: 860px){
    .new-nav {
      width: 330px;
  }

  }




 /* ===================  Media Query 800px  =================== */
 @media (max-width: 800px){
.new-home-banner {flex-wrap: wrap;}
.new-home-banner .new-home-logo {width: 100%;}
.new-home-banner .new-nav {width: 100%; margin-top: 10px;}
.new-home-banner .new-sign {width: 100%; margin-top: 10px;}
.notification-page {margin-top: 230px;}
.slider-inner-header {
  padding-top: 190px;
}
.slider-inner-header {
  padding-top: 130px;
}
.new-order-summary {
  padding-top: 230px;
}
.new-admin {
  margin-top: 100px !important;
}
.about-new-banner{margin-top: 220px;}
main.contact-us {
  margin-top: 220px;
}
}



  /* ===================  Media Query 768px  =================== */
  @media (max-width: 768px){
    .banner-container .new-banner-head {
      font-size: 30px;
      line-height: 45px;

  }
  .new-banner-pera {
    font-size: 20px;
    padding-top: 10px;
    line-height: 28px;
}
.banner-container {
  height: 560px;
}
.new-banner-head-htwo {
  font-size: 30px;
  line-height: 45px;
}
.imagesec-container .slider-info img {
  height: 500px;
  object-fit: cover;

}
.meal-container .meal-box {
  width: 100%;
}
.root_logout .banner-container {
  margin-top: -15px;
}

.banner-container {

    margin-top: -70px;
  }

  .root_login .banner-container{
    margin-top: 91px;
  }


  .new-design-banner .new-design-width-left,
  .new-design-width-right {width: 100%;}
  .home-blog-container .view-blog-box .view-blog-info {
    width: calc(48% - 10px);
    margin: 0 10px 20px;
}
  }
  /* ===================  Media Query 640px  =================== */
  @media (max-width: 640px){

  .home-blog .view-blog-box .overflow-hidden img {
    width: 100%;
    height: 150px;
    object-fit: contain;
}
  }


  /* ===================  Media Query 620px  =================== */
@media (max-width: 620px){
.notification-page {margin-top: 260px;}
.new-admin {
  margin-top: 140px !important;
}
.about-new-banner {
  margin-top: 260px;
}
main.contact-us {
  margin-top: 260px;
}
}



  /* ===================  Media Query 575px  =================== */
  @media (max-width: 575px){
    .new-choose-banner .new-choose-box {
      width: 100%;
      margin: 0px 0px 40px;
  }

  .home-blog h2 {

    padding: 0 70px;
  }
  .home-blog h2::before {
    right: 0px;
}
.home-blog h2::after {
  left: 0px;
}

.about-container img.about-img {
  max-width: 100%;
  margin-right: 0px;
}
.quality-container .about-img img {
  width: 100%;

}
.home-blog-container .view-blog-box .view-blog-info {
  width: 100%;
  margin: 0;
  
}
}

.slider-img {
  width: 100%; /* Adjust based on your design */
  height: auto;
  position: relative;
  opacity: 1;
  transition: opacity 0.3s ease-in-out; /* Smooth fade transition */
}

.fade-in {
  opacity: 1; /* Image fully visible */
}

.fade-out {
  opacity: 0; /* Image fades out */
}
.footer-rights{padding-left: 50px;}
.footer-rights p{color: #fff;}


@media (max-width: 1280px){
  .root_login.arabic .banner-container{
    margin-top: 91px;
  }
}


 @media (max-width: 860px){
.root_login.arabic .about-page {margin-top: 180px;}
.root_login.arabic main .view-blog-banner {
  margin-top: 177px;
}
}
 
@media (max-width: 800px){
  .root_login.arabic .about-page{
    margin-top: 220px;
  }
  .root_login.arabic main .view-blog-banner {
    margin-top: 258px;
}
}

@media (max-width: 732px){
  .root_login.arabic .about-page{
    margin-top: 262px;
  }
}





@media (max-width: 388px){
  .root_login.arabic .about-page{
    margin-top: 300px;
  }
} 


/* Animation */

/* .new-header .new-sign svg:hover{
  background: #ff7200;
  
} */
footer .footer-icon .footer-icon-blank:hover{
  translate: 0 -3px;
}



footer .footer-icon .footer-icon-blank,
.new-choose-banner .new-choose-box{transition: all .1s ease-in-out;}
 .new-choose-banner .new-choose-box:hover .new-choose-banner-icon {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;translate: 0 -3px;
} 



@media (min-width: 1601px){
.about-new-banner,
.about-main-container {
  max-width: 1600px;
}
}

.renewBtn .new-btn-primary {
  /* background: green; */
  /* width: 20px !important; */
  padding: 2px 10px !important;
  font-size: 12px;
}

@media print {
  .print-only {
    display: flex !important;
  }
  .print-hidden {
    display: none !important;
  }
}

@media screen {
  .print-only {
    display: none !important;
  }
}





.weekly-tag{
  z-index: 1;
    top: 5px;
    right: -12px;
    transform: rotate(45deg);
}
.tag-banner{overflow: hidden;border-radius: 1.5rem;}
.tag-banner::before,
.tag-banner::after{
  content: "";
    position: absolute;
    width: 0;
    top: 0;
    height: 0;
    border-top: 80px solid #525252;
    border-right: 80px solid transparent;
    
  
}
.tag-banner::after{
  transform: rotate(90deg);
    right: 0;
}
.tag-banner::before{
left: 0;
transform: rotate(0deg);


}
.new-head-tag{
  color: #fff;
  font-size: 19px !important;
  transform: rotate(-45deg);
  top: 17px;
  left: 6px;
}

.bg-orange.text-white.rounded-xl.mt-0.pl-2.w-64.text-md.flex.items-center{
  margin-top: -10px;
  /* max-width: 200px; */
  text-align: center;
  /* justify-content: center; */
}